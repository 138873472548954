import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';

import MarketLayout from 'web/components/market_layout';

import TermsLayout from '../components/terms_layout';

class CopyrightPage extends Component {
  static reducer(state, action) {
    return MarketLayout.reducer(state, action);
  }

  renderCopyrightPolicy() {
    return (
      <div>
        <h3>Copyright Policy</h3>
        <p>Notification of Copyright Infringement:</p>
        <p>
          Good Eggs, Inc. ({'"'}Good Eggs{'"'}) respects the intellectual property rights of others
          and expects its users to do the same.
        </p>
        <p>
          It is Good Eggs{"'"} policy, in appropriate circumstances and at its discretion, to
          disable and/or terminate the account or access of users who repeatedly infringe or are
          repeatedly charged with infringing the copyrights or other intellectual property rights of
          others.
        </p>
        <p>
          In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be
          found on the U.S. Copyright Office website at{' '}
          <span className="copyright_terms__link">
            <a href="http://www.copyright.gov/legislation/dmca.pdf" target="_blank" rel="noopener">
              http://www.copyright.gov/legislation/dmca.pdf
            </a>
          </span>
          , Good Eggs will respond expeditiously to claims of copyright infringement committed using
          the Good Eggs website and mobile application (the {'"'}Site and Application{'"'}) that are
          reported to Good Eggs’ Designated Copyright Agent, identified in the sample notice below.
        </p>
        <p>
          If you are a copyright owner, or are authorized to act on behalf of one, or authorized to
          act under any exclusive right under copyright, please report alleged copyright
          infringements taking place on or through the Site and Application by completing the
          following DMCA Notice of Alleged Infringement and delivering it to Good Eggs’ Designated
          Copyright Agent. Upon receipt of the Notice as described below, Good Eggs will take
          whatever action, in its sole discretion, it deems appropriate, including removal of the
          challenged material from the Site and Application.
        </p>
        <h4>
          DMCA Notice of Alleged Infringement ({'"'}Notice{'"'})
        </h4>
        <ol>
          <li>
            Identify the copyrighted work that you claim has been infringed, or - if multiple
            copyrighted works are covered by this Notice - you may provide a representative list of
            the copyrighted works that you claim have been infringed.
          </li>
          <li>
            Identify the material that you claim is infringing (or to be the subject of infringing
            activity) and that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit us to locate the material, including at a
            minimum, if applicable, the URL of the link shown on the Site and Application where such
            material may be found.
          </li>
          <li>Provide your mailing address, telephone number, and, if available, email address.</li>
          <li>
            Include both of the following statements in the body of the Notice:
            <ul>
              <li>
                {'"'}I hereby state that I have a good faith belief that the disputed use of the
                copyrighted material is not authorized by the copyright owner, its agent, or the law
                (e.g., as a fair use).{'"'}
              </li>
              <li>
                {'"'}I hereby state that the information in this Notice is accurate and, under
                penalty of perjury, that I am the owner, or authorized to act on behalf of the
                owner, of the copyright or of an exclusive right under the copyright that is
                allegedly infringed.{'"'}
              </li>
            </ul>
          </li>
          <li>
            <p>Provide your full legal name and your electronic or physical signature.</p>
            <p>
              Deliver this Notice, with all items completed, to Good Eggs’ Designated Copyright
              Agent:
            </p>
            <p>
              Copyright Agent
              <br />
              c/o Good Eggs, Inc.
              <br />
              2000 Maritime St.
              <br />
              Oakland, CA 94607
              <br />
              {this.props.copyrightPage.emailAddress}
            </p>
          </li>
        </ol>
      </div>
    );
  }

  render() {
    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
        disableUpcomingOrdersBanner
      >
        <Helmet>
          <title>Copyright Policy | Good Eggs</title>
        </Helmet>

        <TermsLayout currentSlug="copyright">{this.renderCopyrightPolicy()}</TermsLayout>
      </MarketLayout>
    );
  }
}

CopyrightPage.pageName = 'Good Eggs Copyright Policy';

export default connect((state) => state)(CopyrightPage);
